<template>
  <div class="my-orders">
    <div>
      <van-search @change="reload" v-model="keyword" show-action placeholder="请输入订单" />
    </div>
    <van-tabs v-model:active="active" @click="toggle" sticky class="home-tab">
      <van-tab :title="item.title" :name="item.type" :key="item.type" v-for="item in tabData">
        <!-- <van-pull-refresh v-model="isDownLoading" @refresh="onDownRefresh"> -->
        <van-list
          v-model="isUpLoading"
          :finished="upFinished"
          :immediate-check="false"
          :offset="10"
          finished-text="我是有底线的"
          @load="onLoadList"
          style="padding:15px 15px 50px 15px;"
        >
          <div
            v-for="(item,index) in ordersList"
            :key="index"
            class="order-item"
            v-if="ordersList.length!=0"
          >
            <div class="order-title">
              <p>{{item.store_name}}</p>
              <div class="title-type">
                <p v-if="item.pay_status == 0">
                  <span>待支付</span>
                </p>
                <p v-else-if="item.pay_status == 1">
                  <span>已支付</span>
                </p>
              </div>
            </div>
            <div
              class="order-image"
              v-for="(items,ins) in item.products"
              :key="ins"
              @click="goOrderDetail(item.id)"
            >
              <div class="goods-image">
                <img :src="items.image" alt />
                <div class="indo-order">
                  <p>{{items.name}}</p>
                  <div style="color: #666666;font-size: 13px;">{{items.sku_title}}</div>
                </div>
              </div>

              <div style="color: #666666;font-size: 13px;">x{{items.count}}</div>
            </div>
            <div class="TimeCreat">
              <p class="order-time">下单时间:{{item.create_time}}</p>

              <p class="order-price">
                <span style="font-size: 12px;font-weight: 400;">合计</span>
                <span>￥{{item.money}}</span>
              </p>
            </div>
            <div class="order-btn">
              <button class="btn2" v-if="item.pay_status == 0" @click="goOrderDetail(item.id)">待支付</button>
              <button class="btn1" v-if="item.pay_status == 1">已支付</button>
              <button
                class="btn2"
                v-if="item.take_status == 0 && item.pay_status == 1"
                @click="goOrderDetail(item.id)"
              >未核销</button>
              <button class="btn1" v-if="item.take_status == 1 && item.pay_status == 1">已核销</button>
              <button class="btn1" v-if="item.take_status == 2">已取消</button>
              <button class="btn2" v-if="item.comment_status != 0">去评价</button>
            </div>
          </div>

          <van-empty :image="emptyImg" description="暂无订单" v-else />
        </van-list>

        <!-- </van-pull-refresh> -->
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { getOrderList, cancelOrder } from "@/api/order";
import { toUrl } from "@/utils/tools";
import { ref } from "vue";
import { useRoute } from "vue-router";
import { Toast } from "vant";
export default {
  setup() {
    const router = useRoute();
    const active = ref(router.query.type);
    return {
      active,
    };
  },
  computed: {},

  data() {
    return {
      searchOrder: "",
      iconList: "",
      imageUrl: this.url.imageUrl,
      emptyImg: require("@/assets/zwdd.png"),
      tabData: [
        {
          title: "全部",
          type: "0",
        },
        {
          title: "待付款",
          type: "1",
        },
        // {
        // 	title: '待发货',
        // 	type: '2'
        // },
        {
          title: "待收货",
          type: "2",
        },
        {
          title: "待评价",
          type: "3",
        },
      ],
      nullTip: "空空如也~",
      limit: 10, // 每页条数
      page: 1, // 页码
      keyword: "",
      ordersList: [], // 请求数据
      isDownLoading: false, // 下拉刷新
      isUpLoading: false, // 上拉加载
      upFinished: false, // 上拉加载完毕
      offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
      orderStatus: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.path == "/mine") {
      to.meta.keepAlive = false;
    } else {
      to.meta.keepAlive = true;
    }
    next();
  },

  mounted() {
    this.toggle(this.active);
    console.log(this.$route.query.type);
  },
  methods: {
    reload() {
      this.getOrderList();
    },
    goEvaluate() {
      this.$router.push({
        path: "/goEvaluate",
      });
    },
    goOrderDetail(id) {
      console.log(id);
      this.$router.push({
        path: "/h5/orderDetail",
        query: {
          id: id,
        },
      });
    },
    toggle(name) {
      console.log(name);
      this.active = name;
      this.page = 1;
      this.limit = 10;
      this.upFinished = false;
      this.ordersList = [];
      this.getOrderList();
    },
    getOrderList() {
      var that = this;
      that.upFinished = true;
      that.orderStatus = false;
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      let params = {};
      params.type = this.active;
      params.page = this.page;
      params.limit = this.limit;
      params.keyword = this.keyword;
      getOrderList(params).then((res) => {
        const rows = res.data.list;
        if (rows.length > 0 && rows.length == this.limit) {
          this.isUpLoading = true;
          this.upFinished = false;
          // 处理数据
          if (this.page === 1) {
            this.ordersList = rows;
          } else {
            this.ordersList = this.ordersList.concat(rows);
          }
        } else {
          this.isUpLoading = false;
          this.upFinished = true;
          setTimeout(function () {
            that.orderStatus = true;
          }, 1000);
        }

        this.$toast.clear();
      });
    },
    // 取消订单
    cancelOrder(id) {
      console.log(id);
      let params = {};
      params.id = id;
      cancelOrder(params).then((res) => {
        console.log(res.code);
        if (res.code == 200) {
          Toast.success(res.msg);
          this.getOrderList();
        } else {
          Toast.success(res.msg);
          this.getOrderList();
        }
      });
    },
    // 上拉加载请求方法
    onLoadList() {
      this.page++;
      this.getOrderList();
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/style/preset.less";

.my-orders {
  background-color: #f6f6f6;
  font-size: 0.2rem;
  .home-tab {
    ::v-deep .van-tabs__line {
      width: 30px;
      background-color: #fb6228 !important;
      bottom: 22px;
    }
  }

  .order-item {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 15px;
    justify-content: space-between;
    margin-bottom: 15px;
    position: relative;

    .order-title {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      p {
        padding: 0;
        margin: 0;
      }

      .title-type {
        font-size: 12px;
        color: #666666;
      }
    }

    .order-image {
      display: flex;
      align-items: center;
      padding: 10px 0;
      justify-content: space-between;

      .goods-image {
        display: flex;

        img {
          width: 72px;
          height: 55px;
          margin-right: 10px;
          border-radius: 5px;
        }

        .indo-order {
          padding: 10px 5px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          p {
            font-size: 12px;
            margin: 0;
          }
        }
      }

      span {
        font-size: 12px;
        color: #999999;
      }
    }

    .TimeCreat {
      display: flex;
      justify-content: space-between;

      .order-time {
        color: #666666;
        margin: 0;
        font-size: 12px;
      }

      .order-price {
        color: #666666;
        margin: 0;

        span {
          color: #1a1a1a;
          font-weight: bold;
          font-size: 14px;
        }
      }
    }

    .order-btn {
      text-align: right;
      margin-top: 5px;

      .btn1 {
        border: 2px solid #d8d8d8;
        border-radius: 5px;
        background: #ffffff;
        width: 70px;
        height: 29px;
        color: #333333;
        margin-right: 8px;
        font-size: 12px;
      }

      .btn2 {
        border: none;
        border-radius: 5px;
        background: #fc6228;
        width: 70px;
        height: 29px;
        color: #ffffff;
        margin-right: 8px;
        font-size: 12px;
      }
    }
  }
}
</style>
