import request from '@/utils/request'

// 订单列表
export function getOrderList(params) {
    return request({
        url: '/order/lst',
        method: 'get',
		params
    })
}
// 订单详情
export function getOrderDetails(params) {
    return request({
        url: '/order/info',
        method: 'get',
		params
    })
}

// 取消订单
export function cancelOrder(params) {
    return request({
        url: '/order/cancel',
        method: 'get',
		params
    })
}

// 立即购买下单确认接口
export function buyConOrder(params) {
    return request({
        url: '/order/buyConfirm',
        method: 'post',
		params
    })
}

// 立即购买提交订单接口
export function buySubOrder(params) {
    return request({
        url: '/order/buySubmit',
        method: 'post',
		params
    })
}
// 订单支付接口
export function getTN(params) {
    return request({
        url: '/order/pay',
        method: 'post',
		params
    })
}
// 核销码订单信息查询接口
export function getQrcodeInfo(params) {
    return request({
        url: '/order/qrcodeInfo',
        method: 'get',
		params
    })
}
// 订单核销
export function getCheck(params) {
    return request({
        url: '/order/check',
        method: 'get',
		params:params
    })
}


